<template>
  <main class="group-page page">
    <div class="page__inner">
      <SectionComponent>
        <SetsGroupHeader :data="data" />
        <div class="categories-section">
          <SetsCategoryTabsComponent :data="sortedChildGroup" />
          <div class="categories-section--right">
            <SetsCategoryComponent
              v-for="(children_group, i) in sortedChildGroup"
              :key="i"
              :title="children_group.title"
              :id="children_group.id"
            >
              <SetBuyCardComponent
                v-for="(item, i) in children_group.product_sets"
                :key="i"
                :data="item"
                :column="true"
              />
            </SetsCategoryComponent>
          </div>
        </div>
      </SectionComponent>
    </div>
  </main>
</template>

<script>
import SetsGroupHeader from "./components/SetsGroupHeader.vue";
import SetsCategoryComponent from "./components/SetsCategoryComponent.vue";
import SetsCategoryTabsComponent from "./components/SetsCategoryTabsComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import SetBuyCardComponent from "components/SetBuyCardComponent.vue";
import GET from "@/graphql/pages/store/SetGroupPage.graphql";
export default {
  name: "SetGroupPage",
  async asyncData({ apollo, store, route }) {
    let token = store.state.auth_token;
    await apollo.clients.store
      .query({
        query: GET,
        variables: {
          id: parseInt(route.params.id),
        },
        context: {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      })
      .then(({ data }) => {
        store.state.set_group_page = data.set_categories_item;
      });
  },
  data() {
    return {};
  },
  computed: {
    data() {
      return this.$store.state.set_group_page;
    },
    sortedChildGroup() {
      return this.data.child_categories.filter((item) => item.product_sets.length);
    },
  },
  components: {
    SectionComponent,
    SetsGroupHeader,
    SetsCategoryComponent,
    SetsCategoryTabsComponent,
    SetBuyCardComponent,
  },
};
</script>

<style lang="stylus"></style>
