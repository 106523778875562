<template>
  <div class="sets-group-header">
    <ImgComponent :img="data.img" />
    <div class="sets-group-header__text">
      <div class="sets-group-header__title">
        <h1 v-html="data.title" />
        <!--        <div class="sets-group-header__rating">-->
        <!--          <span>-->
        <!--            <RemixIconComponent category="system" name="star-fill" />-->
        <!--            4.7-->
        <!--          </span>-->
        <!--          <p>12 товаров</p>-->
        <!--        </div>-->
      </div>
      <!--      <div class="sets-group-header__buttons">-->
      <!--        <button class="btn btn&#45;&#45;share" @click="share">-->
      <!--          <RemixIconComponent category="system" name="share-line" />-->
      <!--          Поделиться-->
      <!--        </button>-->
      <!--        <button class="btn btn&#45;&#45;white btn&#45;&#45;circle" @click="onFave">-->
      <!--          <RemixIconComponent category="health" name="heart-3-line" />-->
      <!--        </button>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import RemixIconComponent from "components/RemixIconComponent.vue";
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "SetsGroupHeader",
  components: { ImgComponent, RemixIconComponent },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    share() {
      // Handle button 1 click
    },
    onFave() {
      // Handle button 2 click
    },
  },
};
</script>

<style lang="stylus">
.sets-group-header {
  img{
    width: 100%;
    height: 473px;
    border-radius: 32px;
    +below(1024px) {
      height: 200px;
    }
  }
  &__text{
    position absolute
    top 0
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap 80px
    border-radius: 32px;
    padding 128px 0 64px 112px

    +below(1024px) {
      padding 20px
      gap 30px
    }
  }
  &__title {
    display flex;
    flex-direction: column;
    gap 15px
    h1{
      margin 0
      color: var(--gray-gray-900, #292929);
      font-size: 3rem
      font-weight: 500;
      line-height: 120%; /* 57.6px */
      max-width 260px

      +below(1024px) {
        font-size 1.275em
      }
    }
  }
  &__rating{
    display flex
    align-items center
    gap 16px
    span{
      display flex
      align-items center
      gap 4px
      font-weight: 600;
      font-size: 0.75em;
      line-height: 16px;
      color: var(--gray-900);
      background-color var(--gray-100)
      border-radius: 4px;
      width: fit-content;
      padding 4px 8px
      svg{
        width: 15px;
        height: 15px;
        path{
          fill var(--yelow-500)
        }
      }
    }

    p{
      font-weight: 400;
      font-size: 0.75em;
      line-height: 16px;
      color: var(--gray-900);
    }
  }

  &__buttons{
    display flex
    gap 16px
  }
}
</style>
